<template>
  <v-card class="mx-auto mt-3" max-width="400">
    <v-img
      class="error--text align-end"
      src="../../../public/san_francisco.png"
    >
    </v-img>
    <v-card-title>San Francisco de Asís</v-card-title>
    <v-card-text class="pb-0"> Patrono de los Animales y de los Ecologistas</v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-subtitle class="pb-0"> DECRETO DE LA SEMANA </v-card-subtitle>

    <v-card-text class="text--primary" >
      <div class="pt-3">
        “Yo Soy” un Pilar de Sabiduría cósmica que sostiene mi ser, este lugar, toda persona y toda la Tierra.
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SerDeLuz",
};
</script>