<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h3 class="mt-5 pt-4">Articulos anteriores</h3>
    </v-row>
    <v-row class="d-flex justify-center mt-1">
      <v-col cols="12" lg="4" class="mt-2" v-for="(card, i) in cards" :key="i">
        <v-card>
          <v-img :src="card.src" height="325px"></v-img>
          <h4 class="ma-4" v-text="card.titulo"></h4>
          <v-card-text class="pt-0" v-text="card.texto"></v-card-text>
          <v-card-actions>
            <v-btn text color="indigo accent-4" :to="card.to"> LEER</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'OtrosArticulos',

  data() {
    return {
      cards: [
      {
          titulo: 'Señora Araucanía',
          src: `${process.env.BASE_URL}angel_guardian_silencioso_de_chile.png`,
          flex: '4',
          texto: 'La Señora Araucanía es el Guardián Silencioso de Chile, manifestación de...',
          to: `articulos/senora_araucania`,
        },
      {
          titulo: 'Patriotismo Acuariano',
          src: `${process.env.BASE_URL}patriotismo_acuariano.jpg`,
          flex: '4',
          texto: 'El patriotismo es el sentimiento que tiene la “Corriente de Vida” por...',
          to: `articulos/patriotismo_acuariano`,
        },
        {
          titulo: 'Invocación Patria',
          src: `${process.env.BASE_URL}invocacion_patria.png`,
          flex: '4',
          texto: 'A contar del 1° de septiembre, podemos realizar la “Invocación...',
          to: `articulos/invocacion_patria`,
        },
      ],
    };
  },
};
</script>
